<template>
    <card>
        <body-card style="margin: 0;border: none;">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGardenBtriService.details') }}</h4>
            </template>
            <template v-slot:body>
                <b-tabs content-class="mt-0" fill>
                    <!--  Tab of Application Start  -->
                        <div v-if="loading">
                            <Loading />
                        </div>
                        <b-overlay v-else>
                            <div class="text-right">
                                <b-button @click="pdfExport" variant="primary" class="btn btn-sm mb-2">
                                    <i class="ri-printer-line"></i> {{ $t('globalTrans.export_pdf') }}
                                </b-button>
                            </div>
                            <div class="p-3" :class="currentLocale === 'en' ? 'font-en' : 'font-bn'" id="printMe">
                                  <div class="inner-section a11-wrapper">
                                    <body-card>
                                    <template v-slot:body>
                                        <div class="d-flex justify-content-between mb-4">
                                        <p>{{ $t('teaGardenBtriService.registration_no') }}: {{ data.application_no }}</p>
                                        <p>{{ $t('globalTrans.date') }}: {{ data.from_date | dateFormat }}</p>
                                        </div>
                                        <div class="apps-subject-wrapper mb-4">
                                        <p class="mb-0">{{ $t('teaGardenBtriService.manager') }}</p>
                                        <p class="mb-0">{{ $t('teaGardenBtriService.tea_garden') }}</p>
                                        <!-- <p class="mb-0">{{ $t('teaGardenBtriService.upazila_label') }}</p>
                                        <p class="mb-0">{{ $t('globalTrans.district') }}- {{ $t('teaGardenBtriService.district_name') }}</p> -->
                                        </div>
                                        <p class="mb-4">{{ $t('teaGardenBtriService.subject') }}:
                                            <span v-if="currentLocale === 'en'">Advisory inspection schedule for {{moment(data.from_date).format('MMMM') }} - {{moment(data.to_date).format('MMMM YYYY') }} in tea gardens.</span>
                                            <span v-else>চা বাগান সমূহে {{moment(data.from_date).format('MMMM') }} - {{moment(data.to_date).format('MMMM YYYY') }} মাসের উপদেশমূলক পরিদর্শনসূচী।</span>
                                        </p>
                                        <div class="cover-letter-wrapper" style="text-align: justify">
                                        <p class="mb-2">{{ $t('teaGardenBtriService.dear') }}</p>
                                        <p style="line-height: 2rem;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span v-if="currentLocale === 'en'">
                                                According to the instructions of the Chairman, Bangladesh Tea Board, the scientists/officials of the institute will visit the tea plantations in Bangladesh for the purpose of tea production and the quality of human resources.
                                            </span>
                                            <span v-else>
                                                চেয়ারম্যান, বাংলাদেশ চা বোর্ড মহোদয়ের নির্দেশনাক্রমে বাংলাদেশে চায়ের উৎপাদন ও গুনগত মানবৃদ্ধির লক্ষ্যে চা বাগানসমূহে চা আবাদী সম্পর্কিত বিভিন্ন সমস্যাদি সরেজমিনে পর্যবেক্ষণ ও প্রয়োজনীয় পরামর্শ প্রদানের উদ্দেশ্যে নিমোক্ত তারিখ অনুযায়ী অত্র ইনস্টিটিউটের বিজ্ঞানীগণ/কর্মকর্তা পরিদর্শন করবেন।
                                            </span>
                                        </p>
                                        </div>
                                        <b-col sm="12 mt-5">
                                            <table class="table table-sm table-bordered section-tree-view-table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" width="13%">{{ $t('globalTrans.sl_no') }}</th>
                                                        <th class="text-center"><div>{{ $t('globalTrans.date') }}</div></th>
                                                        <th class="text-center">{{ $t('teaGardenConfig.garden_name') }}</th>
                                                        <th class="text-center">{{ $t('teaGardenBtriService.assign_person') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in data.details" :key="index">
                                                        <td style="font-weight: 500;" class="text-center">{{ $n(index + 1) }}</td>
                                                        <td style="font-weight: 500;" class="text-center">{{ item.tentative_date | dateFormat }}</td>
                                                        <td class="text-left">
                                                            <p style="font-weight: 500;" v-for="(gardenItem, gardenIndex) in item.garden_infos" :key="'garden-index-' + gardenIndex">{{ $n(gardenIndex + 1) }}. {{getGardenName(gardenItem.garden_id)}}</p>
                                                        </td>
                                                        <td class="text-left">
                                                            <p style="font-weight: 500;" v-for="(userItem, userIndex) in item.assign_users" :key="'garden-index-' + userIndex">{{ $n(userIndex + 1) }}. {{getUserName(userItem.user_id)}}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-col>
                                        <div class="d-flex justify-content-between mt-5 mx-3">
                                        <p>{{ $t('teaGardenBtriService.thanks') }}</p>
                                            <div v-if="document_signatory" style="text-align:center">
                                                {{ $t('teaGardenBtriService.your_faith') }} <br> <br>
                                                <img style="width:60px" v-if="document_signatory.show_signature" :src="authServiceBaseUrl + 'storage' + document_signatory.signature" alt=""> <br>
                                                <span v-if="document_signatory.show_name">{{ currentLocale === 'en' ? document_signatory.name_en : document_signatory.name_bn}}</span> <br>
                                                <span v-if="document_signatory.show_name">({{getDesignationName(document_signatory.designation_id)}})</span>
                                            </div>
                                        </div>
                                    </template>
                                    </body-card>
                                </div>
                            </div>
                            <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                        </b-overlay>
                    <!--  Tab of Application End  -->
                </b-tabs>
            </template>
        </body-card>
    </card>
</template>
<script>
    import moment from 'moment'
    import RestApi, { authServiceBaseUrl, teaGardenServiceBaseUrl } from '@/config/api_config'
    import { advisoryVisitDetails } from './../../../api/routes'
    import Loading from '../../../components/loading/Details.vue'
    // import Pdf from './details-pdf'
    import Vue from 'vue'
    import VueHtmlToPaper from 'vue-html-to-paper'
    Vue.use(VueHtmlToPaper)
    export default {
        name: 'Details',
        components: {
            Loading
        },
        data () {
            return {
                moment: moment,
                forwarLoad: false,
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                authServiceBaseUrl: authServiceBaseUrl,
                data: {
                    fiscal_year_id: this.$store.state.currentFiscalYearId,
                    details: []
                },
                document_signatory: {},
                userList: [],
                pageStyle: {
                    styles: [
                        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                        `${teaGardenServiceBaseUrl}/custom.css`
                    ]
                }
            }
        },
        created () {
            this.document_signatory = this.$store.state.TeaGardenService.commonObj.masterDocumentSignatoryList.find(item => item.service_id === 22)
            this.getUserList()
            if (this.$route.params.id) {
            const tmp = this.getData()
            this.data = tmp
            }
        },
        computed: {
            authUser: function () {
            return this.$store.state.Auth.authUser
            },
            stepList () {
                const arr1 = [
                    { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
                    { value: 3, text: this.$i18n.locale === 'en' ? 'Assigned' : 'বরাদ্দ করা হয়েছে', text_en: 'Assigned', text_bn: 'বরাদ্দ করা হয়েছে' },
                    { value: 4, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' }
                ]
                const arr2 = []
                if (this.appDetail.return_remarks) {
                    arr2.push({ value: 5, text: this.$i18n.locale === 'en' ? 'Returned' : 'ফেরৎ প্রাপ্ত', text_en: 'Returned', text_bn: 'ফেরৎ প্রাপ্ত' })
                }
                arr2.push({ value: 6, text: this.$i18n.locale === 'en' ? 'Sample Verified' : 'নমুনা যাচাই করা হয়েছে', text_en: 'Sample Verified', text_bn: 'নমুনা যাচাই করা হয়েছে' },
                  { value: 7, text: this.$i18n.locale === 'en' ? 'Report Prepared' : 'প্রতিবেদন প্রস্তুত', text_en: 'Report Prepared', text_bn: 'প্রতিবেদন প্রস্তুত' },
                  { value: 8, text: this.$i18n.locale === 'en' ? 'Report Published' : 'প্রতিবেদন প্রকাশিত', text_en: 'Report Published', text_bn: 'প্রতিবেদন প্রকাশিত' })
                const tcbStepList = arr1.concat(arr2)
                return tcbStepList
            },
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            }
        },
        methods: {
            async pdfExport () {
                const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
                const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 22)
                if (service !== undefined) {
                    if (service.office_type_id) {
                        params.office_type_id = service.office_type_id
                    }
                    if (service.office_id) {
                        params.office_id = service.office_id
                    }
                }
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, advisoryVisitDetails + '/' + this.$route.params.id, params)
                var blob = new Blob([result], {
                    type: 'application/pdf'
                })
                var url = window.URL.createObjectURL(blob)
                window.open(url).print()
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            },
            print () {
                this.$htmlToPaper('printMe', this.pageStyle)
            },
            back () {
                this.$router.go(-1)
            },
            getGardenName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
                if (obj) {
                    return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
                }
            },
            getUserName (id) {
                const obj = this.userList.find(item => item.user_id === id)
                if (obj) {
                    return this.currentLocale === 'en' ? obj.name : obj.name_bn
                }
            },
            getDesignationName (id) {
                const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === id)
                if (obj) {
                    return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
                }
            },
            async getUserList () {
                const btriOfficeId = this.$store.state.CommonService.commonObj.officeList.find(item => item.text_en.toLowerCase() === 'bangladesh tea research institute (btri)').value
                this.userLoading = true
                const params = {
                    office_id: btriOfficeId,
                    org_id: 5
                }
                await RestApi.getData(authServiceBaseUrl, '/user-management/user/all-user-by-org', params).then(response => {
                    const userList = response.map((obj, index) => {
                        return Object.assign({}, obj, {
                            text: this.currentLocale === 'en' ? obj.name : obj.name_bn, value: obj.user_id
                        })
                    })
                    this.userList = userList.filter(item => item !== undefined)
                })
            },
            localizeUserList (locale) {
                const tmp = this.userList.map(item => {
                    return Object.assign({}, item, {
                        text: locale === 'en' ? item.name : item.name_bn
                    })
                })
                this.userList = tmp
            },
            getData () {
                const itemId = this.$route.params.id
                const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
                return JSON.parse(JSON.stringify(tmpData))
            }
        }
    }
</script>
<style scoped>
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #4bb543;
        color:white;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #4bb543;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
    .tagTwo {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
        background: hsl(250deg, 100%, 40%);
        padding: 5px 25px;
        font-weight: 600;
        font-size: 12px;
        color: #FFF;
        transition: clip-path 500ms;
    }
    .tagTwo:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: hsl(252deg, 100%, 60%);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 0;
        transition: transform 500ms;
    }
</style>
